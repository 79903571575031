<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >商务名称：
            <el-autocomplete
              class="ipt_width project_width"
              v-model="parameter.businessName"
              :fetch-suggestions="businessNameQuerySearch"
              placeholder="请输入或选择商务名称"
              @select="onProkectNameSelect"
              :popper-append-to-body="false"
            >
            </el-autocomplete
          ></span>
          <span
            >商务类型：
            <Dictionary
              v-model="parameter.businessType"
              class="ipt_width"
              code="BUSINESS_TYPE"
              placeholder="请选择商务类型"
          /></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              min-width="320"
              prop="businessName"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="businessNumber" label="商务编号"></el-table-column> -->
            <el-table-column
              align="center"
              width="120"
              sortable
              prop="businessType"
              label="商务类型"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="90"
              prop="contacts"
              label="经办人"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="contactsPhone"
              label="经办人电话"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="principalUser"
              width="130"
              sortable
              label="负责人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createdDate"
              width="110"
              sortable
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" sortable width="160" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">暂存</span>
                <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
                <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
                <span v-if="scope.row.status == 5">已移交计划经营部</span>
                <span v-if="scope.row.status == 6">已创建项目</span>
                <!-- <span v-if="scope.row.status == 7">项目完结</span> -->
                <!-- <span v-if="scope.row.status == 8">商务失败</span> -->
                <span v-if="scope.row.status == 9">商务暂停</span>
                <span v-if="scope.row.status == 10">商务终结</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </template>
            </el-table-column>

            <el-table-column prop="title" fixed="right" align="center" width="100" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="zhipai(scope.row)"
                  >指派负责人</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="zhipaiuser(scope.row)"
                  >指派用户</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-show="showEditDialog" :options="form"></Edit>
    <Dispose :showEditDialog.sync="isShow" :Dispose_data="Dispose_data" v-show="isShow"></Dispose>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dispose: () => import('../Trace/Dispose.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null,
      },
      pageSize: 0,
      form: {},
      edit_form: {},
      tableData: [],
      // forList:[],
      loading: false,
      showEditDialog: false,
      dictData: {
        businessType: [],
      },
      Dispose_show: false,
      Dispose_data: {},
      businessList: [],
      isShow: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  provide() {
    return {
      re: this.re,
      retu: this.retu,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()

    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    getData() {
      this.loading = true
      this.$api.businessManage
        .getPrincipalUserList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      // this.parameter.pageNow = val;
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    zhipai(row) {
      this.form = row
      this.showEditDialog = true
    },
    re() {
      this.showEditDialog = false
      this.Dispose_show = false
      this.isShow = false
      this.getData()
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.pageSize,
        total: 0,
        businessName: null,
        businessType: null,
      }
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    zhipaiuser(row) {
      this.Dispose_data = row
      this.Dispose_show = true
      this.isShow = true
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: calc(100vh - 347px);
    }
  }
}
</style>
